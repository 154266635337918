import * as React from 'react'
import { AxiosError } from 'axios'

import {
    QueryClient,
    QueryClientProvider,
    MutationCache,
    QueryCache,
} from '@tanstack/react-query'

import { logError } from '../../../../utils/logError'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            staleTime: 1000 * 60 * 3,
        },
    },
    queryCache: new QueryCache({
        onError: (error, query) => {
            const context = {
                source: 'queryCache',

                queryKey: query.queryKey,
                queryHash: query.queryHash,

                state: query.state,
                options: query.options,
                meta: query.meta,
            }

            if (error instanceof AxiosError) {
                logError(error, {
                    ...context,
                    response: error.response?.data ?? error.message,
                    traceId: error.config?.headers['X-Trace-Id'],
                })
                return
            }

            logError(error, {
                ...context,
                response: error.message,
            })
        },
    }),
    mutationCache: new MutationCache({
        onError: (error, variables, context, mutation) => {
            const sentryContext = {
                source: 'queryCache',

                variables: variables,
                context: context,

                state: mutation.state,
                options: mutation.options,
                meta: mutation.meta,
            }

            if (error instanceof AxiosError) {
                logError(error, {
                    ...sentryContext,
                    response: error.response?.data ?? error.message,
                    traceId: error.config?.headers['X-Trace-Id'],
                })
                return
            }

            logError(error, {
                ...sentryContext,
                response: error.message,
            })
        },
    }),
})

const Provider: React.FC<React.PropsWithChildren> = ({ children }) => {
    return (
        <QueryClientProvider client={queryClient}>
            {children}
        </QueryClientProvider>
    )
}

export default Provider
