import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { QueryClientProvider } from './components/providers/QueryClientProvider'
import { withRouterProvider } from './decorators/withMainRouterProvider'
import './i18n'

const root = document.getElementById('root')

if (root) {
    const RouterProviderWrapper = withRouterProvider()
    createRoot(root).render(
        <StrictMode>
            <QueryClientProvider>
                <RouterProviderWrapper />
            </QueryClientProvider>
        </StrictMode>
    )
}
