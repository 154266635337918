import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import en from '../locales/en/translations.json'
import ro from '../locales/ro/translations.json'

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: 'ro',
    fallbackLng: 'en',
    resources: {
      en: {
        translation: en
      },
      ro: {
        translation: ro
      }
    },
    backend: {
      loadPath: '../locales/{{lng}}/translations.json',  // Path to translation files
    },
    interpolation: {
      escapeValue: false,  // React already escapes values
    },
  });

export default i18n;